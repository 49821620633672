import React, { useState,useEffect} from 'react';
import axios from 'axios'
import Home from './components/Home';
import Recs from './components/Records';
import Trans from './components/Trans';
import Transp from './components/Transp';
import Players from './components/Players';
import Single from './components/Single';
import Admin from './components/Admin';
import About from './components/About';
import Clubs from './components/Clubs';
// import Mheader from './components/Mheader';
import Team from './components/Team';
import History from './components/Qhistory';
import Atrans from './components/Atrans';
// import Nav from './components/Nav';
import './App.css'
import './index.css'
// import About from './components/About';
// import Contact from './components/Contact';
// import Db from './components/Db';
// import Signup from './components/Signup';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  HashRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate
} from "react-router-dom";
// import MyContext from './components/context';
import Signup from './components/Signup';
import Cookies from 'js-cookie';

// Get the value of the cookie with name 'myCookie'

function App() {
  
  const [warm,setWarm] = useState(false)
  useEffect((warm)=>{
    const First = async () => {
      try {
        await axios.get(`${cont.api}warm`).then((res) => {
          if (res.data.success) {
            setWarm(true);
          } else {
            setWarm(false)
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
      }
    }
    First()
  },[warm])
  const cont = {
    gname: 'Emperor6',
    cphone: '+2348130757003',
    email: 'hr.emperor6@emeror6.com',
    
    api: 'https://emperor6be-nghc.onrender.com/',
    // api: 'https://emperor6be.onrender.com/',
    // api: 'http://localhost:1000/',
    // fe: 'http://localhost:3001/',
    fe: 'https://emperor6.com.ng/',
  };
  const [user,setUser] = useState(false)
  const [data,setData] = useState(false)
  const [profits,setProfits] = useState(null)
  const [players,setPlayers] = useState([])
  const [quest,setQuest] = useState()
  const [quests,setQuests] = useState([])
  const updateUser = (newUser) => {
    setUser(newUser);
  };
  const games = [
    {
      name: 'Pick6',
      image: '/images/dice.gif',
      to: '/single',
      gameid: 'tr14246867550852',
      one: 0,
      two: 0,
      three: 0,
      four: 1.2,
      five: 1.5,
      six: 2.0,
      about:
        'Welcome to Pick6, where your fortunes await at the roll of the dice! Step into a world of thrilling uncertainty, where every click brings you closer to uncovering your fate. In this captivating game of chance, players hold their breath as they watch the dice spin, anticipation building with each passing moment. Will luck be on your side, revealing a winning combination that propels you to victory? Or will you face the challenge head-on, embracing the exhilarating risk that comes with every roll? Join us in the excitement of Pick6, where the journey is as exhilarating as the destination. Dare to roll the dice and discover what fate has in store for you!',
    },
    {
      name: 'Team Quest',
      image: '/images/ballers.gif',
      // image: '/images/droll.gif',
      to: '/double',
      gameid: 'tr1323744346786',
      about:
        'Step into the world of Adventures, where every roll counts towards victory! In this adrenaline-fueled game of chance, players embark on a thrilling journey of dice rolls, each one bringing them closer to glory. With only ten rolls at their disposal, every decision becomes crucial as they strategize and aim for the highest possible score. Will you play it safe and secure steady points, or risk it all for the chance of a game-changing roll? As the tension mounts with each toss of the dice, the excitement reaches its peak as players race against time to clinch the title of champion. Join us in After 10, where the quest for victory awaits with every roll of the dice!',
    },
  ];
  const [trans, setTrans] = useState([]);

  return (
    <>
      {/* <MyContext.Provider value={cont}> */}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
            }
          />
          <Route
            path="/sorry"
            element={
              <Home
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
            }
          />
          <Route
            path="/tran"
            element={
              <Home
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
            }
          />
          <Route
            path="/sign"
            element={<Signup cont={cont} user={user} setUser={setUser} />}
          />
          <Route
            path="/about"
            element={<About cont={cont} user={user} setUser={setUser} />}
          />
          <Route
            path="/double"
            element={
              user ? (
                <Team
                  quest={quest}
                  setQuest={setQuest}
                  quests={quests}
                  setQuests={setQuests}
                  cont={cont}
                  games={games}
                  user={user}
                  setUser={setUser}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/recs/:questid"
            element={
              user ? (
                <Recs
                  quest={quest}
                  setQuest={setQuest}
                  quests={quests}
                  setQuests={setQuests}
                  cont={cont}
                  games={games}
                  user={user}
                  setUser={setUser}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/single"
            element={
              user ? (
                <Single
                  cont={cont}
                  games={games}
                  user={user}
                  setUser={setUser}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/history"
            element={<History cont={cont} user={user} setUser={setUser} />}
          />
          <Route
            path="/atrans"
            element={
              user && user.admin ? (
                <Atrans
                  cont={cont}
                  data={data}
                  games={games}
                  trans={trans}
                  setTrans={setTrans}
                  user={user}
                  setUser={setUser}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/trans"
            element={
              user ? (
                <Trans
                  cont={cont}
                  games={games}
                  trans={trans}
                  setTrans={setTrans}
                  user={user}
                  setUser={setUser}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/user/:userid"
            element={
              <Trans
                cont={cont}
                games={games}
                trans={trans}
                setTrans={setTrans}
                user={user}
                setUser={setUser}
              />
            }
          />

          <Route
            path="/admin"
            element={
              user && user.admin ? (
                <Admin
                  cont={cont}
                  data={data}
                  setData={setData}
                  setPlayers={setPlayers}
                  trans={trans}
                  setTrans={setTrans}
                  user={user}
                  setUser={setUser}
                  setProfits={setProfits}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/virtual"
            element={
              user ? (
                <Clubs
                  cont={cont}
                  data={data}
                  setData={setData}
                  setPlayers={setPlayers}
                  trans={trans}
                  setTrans={setTrans}
                  user={user}
                  setUser={setUser}
                  setProfits={setProfits}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/players"
            element={
              user && user.admin ? (
                <Players
                  cont={cont}
                  profits={profits}
                  players={players}
                  setPlayers={setPlayers}
                  data={data}
                  user={user}
                  setUser={setUser}
                />
              ) : (
                <Navigate to="/sorry" />
              )
            }
          />
          <Route
            path="/*"
            element={
              <Home cont={cont} user={user} games={games} setUser={setUser} />
            }
          />
        </Routes>
      </Router>
      {/* </MyContext.Provider> */}
    </>
  );
}

export default App;
